import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "firstInput" ]

  connect() {
    if (this.otherOpenModal) {
      this.closeAllOtherModals()
      this.openModal()
    } else {
      setTimeout(() => this.openModal(), 50)
    }

    // Event handlers for closing the modal
    this.element.addEventListener("keyup", (e) => this.escClose(e))
    this.element.addEventListener("click", (e) => this.clickClose(e))
  }

  openModal() {
    this.element.classList.add("opened")
    if (this.hasFirstInputTarget) this.firstInputTarget.focus()
  }

  closeAllOtherModals() {
    document.querySelectorAll('[data-controller="modal"]').forEach(function(modal) {
      // As long as it's not this modal
      if (this.element != modal) document.body.removeChild(modal)
    }.bind(this))
  }

  get otherOpenModal() {
    return document.querySelectorAll('[data-controller="modal"]').length > 1
  }

  close() {
    this.element.classList.remove("opened")
    setTimeout(function() {
      if (this.element.parentElement) this.element.parentElement.removeChild(this.element)
    }.bind(this), 500)
  }

  escClose(event) {
    if (event.keyCode == 27) this.close()
  }

  clickClose(event) {
    if (event.target == this.element) this.close()
  }

}