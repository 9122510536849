import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "list" ]

  toggle(event) {
    event.stopPropagation()
    this.element.classList.toggle("open")
  }

  close(event) {
    if(!this.element.contains(event.target)) {
      this.element.classList.remove("open")
    }
  }

  switch(event) {
    let url = event.currentTarget.dataset.url
    webkit.messageHandlers.robodash.postMessage({message: "switchDashboard", url: url})
  }

}