import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "menu" ]

  toggle() {
    this.element.classList.toggle('dropdown-open')
  }

  close(event) {
    if(!this.element.contains(event.target)) {
      this.element.classList.remove('dropdown-open')
    }
  }

}