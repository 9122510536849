import { Controller } from "stimulus"
import Chart from "chart.js"
import csv2json from "csvjson-csv2json"

export default class extends Controller {
  static targets = ["canvas"]

  connect() {
    // First set default config
    this.setChartDefaults()
    this.fetchData()
  }

  fetchData() {
    // Fetch the data and parse it as JSON
    fetch(this.source).then(async function(response) {
      if (response.ok) {
        // Transpose it and first column is a key
        let data = csv2json(await response.text(), {transpose: true, hash: true})
        let datasets = new Array

        // Loop all sets are range
        Object.keys(data).forEach(function(dataset) {
          datasets.push({label: dataset, values: Object.values(data[dataset])})
        })

        this.chart = new Chart(this.canvas, this.options(datasets))
      } else {
        throw new Error("No 200 response")
      }
    }.bind(this)).catch(function(error) {
      alert(`There was an error loading your chart: ${this.canvasTarget.dataset.name}.`)
    }.bind(this))
  }

  get canvas() {
    return this.canvasTarget.getContext('2d')
  }

  get chartType() {
    return this.canvasTarget.dataset.type || "line"
  }

  get source() {
    return this.canvasTarget.dataset.source
  }

  get lineChart() {
    return this.chartType == "line"
  }

  setChartDefaults() {
    // Fonts
    Chart.defaults.global.defaultFontFamily = 'brandon-grotesque'
    Chart.defaults.global.defaultFontColor = '#A8BAC7AA'
    Chart.defaults.global.defaultFontSize = 13

    // Legend
    Chart.defaults.global.legend.labels.usePointStyle = true
    Chart.defaults.global.legend.labels.boxWidth = 2
    Chart.defaults.global.legend.labels.padding = 20
    Chart.defaults.global.legend.labels.fontSize = 16

    // Layout
    Chart.defaults.global.layout.padding.top = 20

    // Tooltips
    Chart.defaults.global.tooltips.backgroundColor = 'transparent'
    Chart.defaults.global.tooltips.titleFontSize = 0
    Chart.defaults.global.tooltips.bodyFontColor = '#F3E2D8'
    Chart.defaults.global.tooltips.bodyFontStyle = 'bold'
    Chart.defaults.global.tooltips.displayColors = false
    Chart.defaults.global.tooltips.xPadding = 10
    Chart.defaults.global.tooltips.yPadding = 10
    Chart.defaults.global.tooltips.borderWidth = 0
  }

  options(datasets) {
    return {
      type: this.chartType,
      data: {
        labels: datasets[0]["values"],
        datasets: this.styleDatasets(datasets)
      },
      options: {
        scales: {
          xAxes: [{barThickness: 15, gridLines: {display: false}, display: false, ticks: {padding: -100}}],
          yAxes: [{
            gridLines: {color: '#A8BAC722', zeroLineColor: '#A8BAC799', zeroLineWidth: 0, drawBorder: false, tickMarkLength: -40},
            ticks: {beginAtZero: true, maxTicksLimit: 5, padding: 0, labelOffset: -12}
          }]
        }
      }
    }
  }

  styleDatasets(datasets) {
    let colors = ['#E86957', '#F3E2D8', '#A8BAC7']

    return datasets.map(function(dataset, index) {
      return {
        label: dataset["label"],
        data: dataset["values"],
        backgroundColor: this.lineChart ? this.gradientBackground : colors[index % colors.length],
        borderColor: colors[index % colors.length],
        lineTension: 0,
        borderWidth: 3,
        pointBackgroundColor: colors[index % colors.length],
        pointHitRadius: 30
      }
    }.bind(this))
  }

  get gradientBackground() {
    let gradientFill = this.canvas.createLinearGradient(0, 0, 480, 250)
    gradientFill.addColorStop(1, '#0B2234AA')
    gradientFill.addColorStop(0, '#07172433')
    return gradientFill
  }

}