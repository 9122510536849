import { Controller } from "stimulus"
import Sortable from "sortablejs"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = [ "widget" ]

  connect() {
    consumer.subscriptions.create({channel: "WidgetsChannel", dashboard_id: this.id}, {
      received: function(data) {
        let widget = this.widgets.find((widget) => widget.dataset.widgetHashid == data.widget.hashid)
        if (widget) {
          if (data.widget.destroyed) {
            this.element.removeChild(widget)
          } else {
            widget.outerHTML = data.widget.html
          }
        } else {
          this.element.insertAdjacentHTML("afterbegin", data.widget.html)
        }
      }.bind(this)
    })

    // Sortable
    if (this.sortWidgetsUrl) this.initSortable()
  }

  initSortable() {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      dataIdAttr: 'data-widget-hashid',
      onSort: this.sortChanged()
    })
  }

  sortChanged() {
    return function(event) {
      fetch(this.sortWidgetsUrl, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': this.token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({widgets: this.sortable.toArray()})
      })
    }.bind(this)
  }

  get token() {
    return document.querySelector('meta[name="csrf-token"]').content
  }

  get sortWidgetsUrl() {
    return this.element.dataset.sortWidgetsUrl
  }

  get id() {
    return this.element.dataset.dashboardId
  }

  get widgets() {
    return Array.from(this.widgetTargets)
  }

}