import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "edit" ]

  connect() {
    this.editTarget.style.display = (this.admin && !this.isApp) ? "flex" : "none"
  }

  get admin() {
    return document.body.hasAttribute("data-admin")
  }

  get isApp() {
    return document.body.dataset.variant == "app"
  }

}