import { Controller } from "stimulus"

export default class extends Controller {

  open(event) {
    let url = event.currentTarget.dataset.url
    webkit.messageHandlers.robodash.postMessage({message: "form", url: url})
  }

  logout(event) {
    webkit.messageHandlers.robodash.postMessage({message: "logout"})
  }

}