import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.element["authController"] = this
    this.authenticate()
  }

  authenticate() {
    webkit.messageHandlers.robodash.postMessage({
      message: "authenticate", 
      id: this.userId, 
      status: this.userStatus
    })
  }

  get userId() {
    return document.querySelector('body').dataset.userId
  }

  get userStatus() {
    return document.querySelector('body').dataset.userStatus
  }

}